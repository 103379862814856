import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  '@keyframes shine-lines': {
    '0%': {
      left: '-100%',
    },
    '100%': {
      left: '100%',
    },
  },
  'grid-area': {
    display: 'grid',
    gridGap: props => props.gridGap,
    alignItems: 'center',
  },
  'bg-gradient': {
    position: 'relative',
    margin: props => props.margin,
    backgroundColor: props => props.bgColor,
    overflow: 'hidden',
    '&::after': {
      animation: '$shine-lines 1.5s infinite linear',
      content: '""',
      left: '0',
      top: '0',
      position: 'absolute',
      height: '100%',
    },
  },
});
