import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Placeholder = ({
  isMount,
  loading,
  width,
  height,
  background,
  style = {},
  children,
}) => {
  const styles = useStyles({
    width,
    height,
    background,
    loadingSize: loading || 'auto',
  });

  const LoadablePlaceholder = useMemo(() => {
    switch (typeof loading) {
      case 'number':
        return (
          <div className={styles['loading__container']}>
            <span className="indicator-loading"></span>
          </div>
        );
      default:
        return null;
    }
  }, [loading]);

  return (
    <div className={styles['placeholder__container']} style={{ ...style }}>
      {isMount ? children : LoadablePlaceholder}
    </div>
  );
};
Placeholder.defaultProps = {
  isMount: false,
  height: 'auto',
  width: 'auto',
};
Placeholder.propTypes = {
  isMount: PropTypes.bool,
  background: PropTypes.string.isRequired,
  style: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.number,
};
export default React.memo(Placeholder);
