import { makeStyles } from '@material-ui/core/styles';
import { Shadow } from 'static/styles/jss/abstracts/mixins';
import variables from 'static/styles/jss/abstracts/variables';

export default makeStyles({
  'intro-pointer': {
    position: 'fixed',
    zIndex: '999999999',
    boxShadow: props => props.backdropShadow,
    width: props => props.pointerRect.width,
    height: props => props.pointerRect.height,
    top: props => props.pointerRect.y,
    left: props => props.pointerRect.x,
    borderRadius: props => props.radiusPointer,
    transition: 'top .3s, left .3s, width .3s, height .3s',
    '&::after': {
      content: '""',
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
    },
  },
  'intro-modal-container': {
    top: props => props.modal.y,
    left: props => props.modal.x,
    position: 'fixed',
    zIndex: '9999999999',
    transition: 'top .3s, left .3s',
    background: '#fff',
    ...Shadow(
      '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
    ),
    width: props => props.modal.width,
    borderRadius: '4px',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: props => props.modal.rectangleAxis.x,
      margin: 'auto',
      top: '-7px',
      width: '0',
      height: '0',
      borderLeft: props => `${props.modal.rectangleWidth}px solid transparent`,
      borderRight: props => `${props.modal.rectangleWidth}px solid transparent`,
      borderBottom: props => `${props.modal.rectangleWidth}px solid #fff`,
      transition: props => props.rectangleTransition,
    },
    '&.popup-container__top': {
      '&::after': {
        top: 'auto',
        bottom: '-7px',
        transform: 'rotate(-180deg)',
      },
    },
    '&.popup-container__left': {
      '&::after': {
        top: props => props.modal.rectangleAxis.y,
        left: 'auto',
        right: '-10px',
        transform: 'rotate(90deg)',
      },
    },
    '&.popup-container__right': {
      '&::after': {
        top: props => props.modal.rectangleAxis.y,
        left: '-10px',
        right: 'auto',
        transform: 'rotate(-90deg)',
      },
    },
    '& .intro-modal--header': {
      padding: '16px 24px',
      margin: '0',
      '& h3': {
        fontSize: '19px',
        fontWeight: 'bold',
        letterSpacing: '-0.5px',
        margin: '0',
      },
    },
    '& .intro-modal--body': {
      margin: '0 2px',
      padding: '8px 20px',
      '& p': {
        color: variables.$modalDescDarkColor,
      },
    },
  },
  'intro-modal-actions--wrapper': {
    padding: '8px 24px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      color: '#3378D1',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
      fontSize: '13px',
      background: 'none',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  },
});
