import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import Portal from 'hoc/Portal';
import useElPositionDetector from 'hooks/use-el-pos-detector';

import { deviceParams } from 'store/reselect';

import useStyles from './styles';
import getIntroSteps from './config';

const scrollY = 0;

const MARGIN_POPUP = 10;
const RECTANGLE_WIDTH = 10;
const CONTAINER_WIDTH = {
  desktop: 320,
  mobile: 280,
};

const getRectanglePosition = (pointerRect, modalRect) => {
  const left =
    pointerRect.x +
    pointerRect.width / 2 -
    (modalRect.x + modalRect.width / 2) +
    modalRect.width / 2 -
    RECTANGLE_WIDTH;
  const top =
    pointerRect.y +
    pointerRect.height / 2 -
    (modalRect.y + modalRect.height / 2) +
    modalRect.height / 2 -
    RECTANGLE_WIDTH / 2;
  const tillXEnd = modalRect.width - (2 * MARGIN_POPUP + RECTANGLE_WIDTH);
  const tillYEnd = modalRect.height - (2 * MARGIN_POPUP + RECTANGLE_WIDTH);

  return {
    x: left > tillXEnd ? tillXEnd : left < MARGIN_POPUP ? MARGIN_POPUP : left,
    y: top > tillYEnd ? tillYEnd : top < MARGIN_POPUP ? MARGIN_POPUP : top,
  };
};

const animationSlideRectangleIfDataExist = (obj1, obj2) =>
  Object.values(obj1).every(e => e >= 0) &&
  Object.values(obj2).every(e => e >= 0);

const Intro = ({
  initialStep,
  popupPlacement,
  flexible,
  finishTour,
  somethingWentWrong,
}) => {
  const { deviceType } = useSelector(deviceParams());
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [popupPlacementDetect, setPopupPlacementDetect] =
    useState(popupPlacement);
  const [flexiblePlacementMode, setFlexiblePlacementMode] =
    useState(popupPlacementDetect);
  const [disableWatchingDuringScroll, setDisableWatchingDuringScroll] =
    useState(false);
  const [isInViewPort, setIsInViewPort] = useState(false);
  const [introElement, setIntroElement] = useState(null);
  const [pointerRect, setPointerRect] = useState({
    width: null,
    height: null,
    x: null,
    y: null,
  });
  const [modalRect, setModalRect] = useState({
    width: null,
    height: null,
    x: null,
    y: null,
  });

  const steps = getIntroSteps(deviceType === 'mobile');
  const [mounted, setMounted] = useState(false);
  const { positionTarget, foundedNode } = useElPositionDetector(
    `[data-intro="${steps[currentStep]?.element}"]`,
    disableWatchingDuringScroll,
  );

  const styles = useStyles({
    radiusPointer: steps[currentStep]?.radiusPointer || '50%',
    pointerRect,
    backdropShadow: `rgb(255 234 234 / 0%) 0px 0px 1px 2px, rgb(0 0 0 / 68%) 0px 0px 0px ${
      2 * (mounted ? Math.max(window.innerHeight, window.innerWidth) : 1920)
    }px`,
    modal: {
      rectangleTransition: animationSlideRectangleIfDataExist(
        pointerRect,
        modalRect,
      )
        ? '.3s'
        : '0s',
      width: CONTAINER_WIDTH[deviceType] || CONTAINER_WIDTH.desktop,
      x: modalRect.x,
      y: modalRect.y,
      rectangleAxis: getRectanglePosition(pointerRect, modalRect),
      rectangleWidth: RECTANGLE_WIDTH,
    },
  });
  const modalRef = useRef();

  const rectOfPointer = useMemo(
    () => Object.keys(pointerRect).every(e => e !== null),
    [pointerRect],
  );

  const checkIfNodeIsOnViewPort = useCallback(foundedNode => {
    const nodeRect = foundedNode.getBoundingClientRect();
    return (
      nodeRect.top + nodeRect.height < window.innerHeight && nodeRect.top > 0
    );
  }, []);

  const setPointerPosition = useCallback(() => {
    const currentDOMElement = document.querySelector(
      `[data-intro="${steps[currentStep]?.element}"]`,
    );
    setIntroElement(currentDOMElement);
    if (currentDOMElement) {
      const inViewPort = checkIfNodeIsOnViewPort(currentDOMElement);
      setIsInViewPort(inViewPort);
      if (!inViewPort) {
        setDisableWatchingDuringScroll(true);
      } else {
        const { width, height, x, y } =
          currentDOMElement.getBoundingClientRect();
        setPointerRect({
          width: steps[currentStep]?.pointer?.width || width,
          height: steps[currentStep]?.pointer?.height || height,
          x: steps[currentStep]?.pointer?.width
            ? x - (steps[currentStep].pointer.width / 2 - width / 2)
            : x,
          y: steps[currentStep]?.pointer?.height
            ? y - (steps[currentStep].pointer.height / 2 - height / 2)
            : y,
        });
      }
    }
  }, [currentStep, steps]);

  const setModalPosition = useCallback(() => {
    if (modalRef.current) {
      const modalBoundingRect = modalRef.current.getBoundingClientRect();
      const modalPos = {};
      const winW = window.innerWidth;
      const winH = window.innerHeight;
      let _y = null;
      let _x = null;

      if (popupPlacementDetect === 'left' || popupPlacementDetect === 'right') {
        const leftX = pointerRect.x - modalBoundingRect.width - MARGIN_POPUP;
        const rightX = pointerRect.x + pointerRect.width + MARGIN_POPUP;

        if (flexible) {
          // X axis
          if (popupPlacementDetect === 'right') {
            if (
              pointerRect.x +
                pointerRect.width +
                MARGIN_POPUP +
                modalBoundingRect.width >
              winW
            ) {
              _x = leftX;
              setFlexiblePlacementMode('left');
            } else {
              _x = rightX;
              setFlexiblePlacementMode('right');
            }
          } else if (
            pointerRect.x - MARGIN_POPUP - modalBoundingRect.width <
            MARGIN_POPUP
          ) {
            _x = rightX;
            setFlexiblePlacementMode('right');
          } else {
            _x = leftX;
            setFlexiblePlacementMode('left');
          }
          _x =
            _x < MARGIN_POPUP
              ? MARGIN_POPUP
              : _x + modalBoundingRect.width + MARGIN_POPUP > winW
              ? winW - modalBoundingRect.width - MARGIN_POPUP
              : _x;

          // Y axis
          if (
            modalBoundingRect.height / 2 >
            pointerRect.y + pointerRect.height / 2
          ) {
            _y = MARGIN_POPUP;
          } else if (
            pointerRect.y +
              pointerRect.height / 2 +
              modalBoundingRect.height / 2 >
            winH
          ) {
            _y = winH - (modalBoundingRect.height + MARGIN_POPUP);
          } else {
            _y =
              pointerRect.y +
              pointerRect.height / 2 -
              modalBoundingRect.height / 2;
          }
        } else {
          _x =
            popupPlacementDetect === 'right'
              ? pointerRect.x + pointerRect.width + MARGIN_POPUP
              : leftX;
          _y =
            pointerRect.y +
            pointerRect.height / 2 -
            modalBoundingRect.height / 2;
        }
      } else if (
        popupPlacementDetect === 'top' ||
        popupPlacementDetect === 'bottom'
      ) {
        const topY = pointerRect.y - MARGIN_POPUP - modalBoundingRect.height;
        const bottomY = pointerRect.y + pointerRect.height + MARGIN_POPUP;
        if (flexible) {
          // X Axis
          if (
            pointerRect.x +
              pointerRect.width / 2 -
              (modalBoundingRect.width / 2 + MARGIN_POPUP) <
            MARGIN_POPUP
          ) {
            _x = MARGIN_POPUP;
          } else {
            _x =
              pointerRect.x +
              pointerRect.width / 2 -
              modalBoundingRect.width / 2;
          }
          _x =
            _x < MARGIN_POPUP
              ? MARGIN_POPUP
              : _x + modalBoundingRect.width + MARGIN_POPUP > winW
              ? winW - modalBoundingRect.width - MARGIN_POPUP
              : _x;

          // Y Axis
          if (popupPlacementDetect === 'top') {
            if (
              pointerRect.y - MARGIN_POPUP - modalBoundingRect.height <
              MARGIN_POPUP
            ) {
              _y = bottomY;
              setFlexiblePlacementMode('bottom');
            } else {
              _y = topY;
              setFlexiblePlacementMode('top');
            }
          } else if (
            pointerRect.y +
              pointerRect.height +
              MARGIN_POPUP +
              modalBoundingRect.height +
              MARGIN_POPUP >
            winH
          ) {
            _y = topY;
            setFlexiblePlacementMode('top');
          } else {
            _y = bottomY;
            setFlexiblePlacementMode('bottom');
          }
          _y =
            _y + MARGIN_POPUP + modalBoundingRect.height > winH
              ? winH - MARGIN_POPUP - modalBoundingRect.height
              : _y < MARGIN_POPUP
              ? MARGIN_POPUP
              : _y;
        } else {
          _y = popupPlacementDetect === 'top' ? topY : bottomY;
          _x =
            pointerRect.x +
            pointerRect.width / 2 -
            (modalBoundingRect.width / 2 + MARGIN_POPUP);
        }
      }
      modalPos.x = _x;
      modalPos.y = _y;
      modalPos.width = modalBoundingRect.width;
      modalPos.height = modalBoundingRect.height;
      setModalRect(modalPos);
    }
  }, [modalRef, popupPlacementDetect, pointerRect, flexible]);

  const nextTour = () => {
    setCurrentStep(prevState => ++prevState);
  };

  const finishJob = () => {
    finishTour();
  };

  useEffect(() => {
    if (!isInViewPort && disableWatchingDuringScroll && introElement) {
      document.body.classList.remove('body--fixed__overflow');
      const offsetTop = introElement.getBoundingClientRect().top;
      const scrollDir = offsetTop < 0 ? 'top' : 'bottom';
      const scrollStep = 80;
      const offsetTopY = offsetTop + scrollY - 100;
      let animScroll = scrollY;
      let reqAF;
      function scrollTo() {
        animScroll += scrollDir === 'top' ? -scrollStep : scrollStep;
        window.scrollTo({
          top: animScroll,
          left: 0,
        });
        if (
          (scrollDir === 'top' && animScroll < offsetTopY) ||
          (scrollDir === 'bottom' && animScroll + scrollStep > offsetTopY)
        ) {
          cancelAnimationFrame(reqAF);
          document.body.classList.add('body--fixed__overflow');
          setDisableWatchingDuringScroll(false);
        } else {
          reqAF = requestAnimationFrame(scrollTo);
        }
      }
      scrollTo();
    }
  }, [isInViewPort, disableWatchingDuringScroll, introElement]);

  useEffect(() => {
    if (!deviceType) return;
    if (deviceType === 'mobile') {
      setPopupPlacementDetect('bottom');
    } else {
      setPopupPlacementDetect(popupPlacement);
    }
  }, [deviceType]);

  useEffect(() => {
    setPointerPosition();
  }, [positionTarget]);

  useEffect(() => {
    setModalPosition();
  }, [pointerRect, popupPlacementDetect]);

  useEffect(() => {
    if (!foundedNode) return;
    if (foundedNode !== 'no-target') {
      setMounted(true);
      document.body.classList.add('body--fixed__overflow');
    } else {
      setMounted(false);
      somethingWentWrong(false);
      document.body.classList.remove('body--fixed__overflow');
    }
  }, [foundedNode]);

  useEffect(
    () => () => {
      document.body.classList.remove('body--fixed__overflow');
    },
    [],
  );

  const component = steps[currentStep];
  return mounted ? (
    <Portal>
      <>
        {rectOfPointer ? <div className={styles['intro-pointer']}></div> : ''}
        <div
          className={`${styles['intro-modal-container']} popup-container__${flexiblePlacementMode}`}
          ref={modalRef}
        >
          <div className="intro-modal--header">
            <h3>{component.title}</h3>
          </div>
          <div className="intro-modal--body">
            <p>{component.description}</p>
          </div>
          <div className={styles['intro-modal-actions--wrapper']}>
            {component.next ? (
              <>
                <button onClick={finishJob}>Skip Tour</button>
                <button onClick={nextTour}>Next</button>
              </>
            ) : (
              <button onClick={finishJob}>I got It</button>
            )}
          </div>
        </div>
      </>
    </Portal>
  ) : null;
};
Intro.defaultProps = {
  initialStep: 0,
  popupPlacement: 'bottom',
  flexible: true,
  somethingWentWrong: () => void 0,
};

export default React.memo(Intro);
