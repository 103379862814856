import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { UiGenerateMargin } from 'utils/handlers';

import useStyles from './styles';

const gradient = {
  grey: {
    main: 'rgb(234 234 234)',
    gradient: 'rgb(241 239 239)',
  },
  blue: {
    main: 'rgb(42 91 159)',
    gradient: 'rgb(48 97 162)',
  },
};
const Skeleton = ({
  grid,
  gridGap = 10,
  style = {},
  repeatCount,
  withOpacity,
  bgType,
  alignment,
}) => {
  const styles = useStyles({
    gridGap,
    bgColor: gradient[bgType].main,
  });

  const renderEachView = useCallback(
    (col, colItemIndex = '', colIndex, rowIndex) => (
      <div
        key={colIndex + rowIndex + colItemIndex}
        style={{
          width: col.w || 'auto',
          height: col.h,
          borderRadius: col.r,
          margin: UiGenerateMargin(col.margin || []).margin,
        }}
        className={`${
          styles['bg-gradient']
        } skeleton-${`${rowIndex}_${colIndex}_${colItemIndex}`}`}
      >
        {/* eslint-disable-next-line react/no-unknown-property */}
        <style jsx={true}>{`
          .skeleton-${`${rowIndex}_${colIndex}_${colItemIndex}`}:after {
            width: ${col.skeletonW || 100}px;
            background-image: linear-gradient(
              90deg,
              ${gradient[bgType].main} 0px,
              ${gradient[bgType].gradient} ${(col.skeletonW || 100) / 2}px,
              ${gradient[bgType].main} ${col.skeletonW || 100}px
            );
          }
        `}</style>
      </div>
    ),
    [grid],
  );

  const setOpacity = useCallback(
    viewIndex =>
      (repeatCount || grid.length) > 0 && withOpacity
        ? 1 - (1 / (repeatCount || grid.length)) * viewIndex
        : 1,
    [repeatCount, withOpacity, grid],
  );

  const generateWrapper = useCallback(
    (row, rowIndex) => {
      const gridArea = row.reduce((acc, item) => {
        acc += item instanceof Array ? '1fr' : `${item.w} `;
        return acc;
      }, '1fr / ');

      return (
        <div
          style={{
            grid: gridArea,
            opacity: setOpacity(rowIndex),
            justifyContent: alignment[rowIndex] || 'flex-start',
          }}
          key={rowIndex}
          className={styles['grid-area']}
        >
          {row.map((col, colIndex) =>
            col instanceof Array ? (
              <div key={rowIndex + colIndex}>
                {col.map((item, colItemIndex) =>
                  renderEachView(item, colItemIndex, colIndex, rowIndex),
                )}
              </div>
            ) : (
              renderEachView(col, null, colIndex, rowIndex)
            ),
          )}
        </div>
      );
    },
    [grid, alignment],
  );

  const generateContainer = useCallback(
    () =>
      (repeatCount > 0 ? [].constructor(repeatCount).fill(grid[0]) : grid).map(
        (row, rowIndex) => generateWrapper(row, rowIndex),
      ),
    [repeatCount, grid, generateWrapper],
  );

  return <div style={style}>{generateContainer()}</div>;
};
Skeleton.defaultProps = {
  bgType: 'blue',
  alignment: {},
};
Skeleton.propTypes = {
  grid: PropTypes.array.isRequired,
  gridGap: PropTypes.number,
  withOpacity: PropTypes.bool,
  repeatCount: PropTypes.number,
  style: PropTypes.object,
  alignment: PropTypes.object,
  bgType: PropTypes.string,
  // margin: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
};
export default React.memo(Skeleton);
