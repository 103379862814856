import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  '@keyframes indicator': {
    from: {
      '-webkit-transform': 'rotate(0deg)',
      transform: 'rotate(0deg)',
    },
    to: {
      '-webkit-transform': 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
  },
  placeholder__container: {
    height: props => props.height,
    width: props => props.width,
  },
  loading__container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .indicator-loading': {
      fontSize: '10px',
      margin: '50px auto',
      textIndent: '-9999em',
      width: props => props.loadingSize,
      height: props => props.loadingSize,
      borderRadius: '50%',
      background:
        'linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%)',
      position: 'relative',
      '-webkit-animation': '$indicator 1.4s infinite linear',
      animation: '$indicator 1.4s infinite linear',
      '-webkit-transform': 'translateZ(0)',
      '-ms-transform': 'translateZ(0)',
      transform: 'translateZ(0)',
      '&:before': {
        width: '50%',
        height: '50%',
        background: '#ffffff',
        borderRadius: '100% 0 0 0',
        position: 'absolute',
        top: '0',
        left: '0',
        content: '""',
      },
      '&:after': {
        background: props => props.background,
        width: '90%',
        height: '90%',
        borderRadius: '50%',
        content: '""',
        margin: 'auto',
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
      },
    },
  },
});
