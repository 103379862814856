import { useEffect, useState } from 'react';

let reqID;
const WATCHING_TARGET_TILL_TIME = 2000;
function rqAnimHandler({
  countWatching,
  targetNode,
  targetSelector,
  setFoundedNode,
  lastPosition,
  setPositionTarget,
  maxTimeSeekingElement,
}) {
  countWatching++;
  if (!targetNode) {
    targetNode = document.querySelector(targetSelector);
    targetNode && setFoundedNode(targetNode);
  } else {
    const newPosition = targetNode.getBoundingClientRect();
    if (
      lastPosition.top !== newPosition.top ||
      lastPosition.left !== newPosition.left
    ) {
      lastPosition = newPosition;
      setPositionTarget(lastPosition);
      setFoundedNode(targetNode);
    }
  }
  if (countWatching > maxTimeSeekingElement && !targetNode) {
    setFoundedNode('no-target');
    cancelAnimationFrame(reqID); // Should cancel watching if the observer can't find the node
  } else {
    reqID = requestAnimationFrame(
      rqAnimHandler.bind(null, {
        countWatching,
        targetNode,
        targetSelector,
        setFoundedNode,
        lastPosition,
        setPositionTarget,
        maxTimeSeekingElement,
      }),
    );
  }
}

const useElPosDetector = (targetSelector, disableWatching) => {
  const [positionTarget, setPositionTarget] = useState({});
  const [foundedNode, setFoundedNode] = useState(null);

  function startWatcher() {
    const targetNode = document.querySelector(targetSelector);
    const rAFSpeed = 1000 / 60;
    const maxTimeSeekingElement = Math.round(
      WATCHING_TARGET_TILL_TIME / rAFSpeed,
    );
    const countWatching = 0;
    const lastPosition = {};

    rqAnimHandler({
      countWatching,
      targetNode,
      targetSelector,
      setFoundedNode,
      lastPosition,
      setPositionTarget,
      maxTimeSeekingElement,
    });
  }

  useEffect(() => {
    if (reqID) {
      cancelAnimationFrame(reqID);
    }
    startWatcher();
  }, [targetSelector]);

  useEffect(() => {
    if (disableWatching) {
      cancelAnimationFrame(reqID);
    } else {
      if (reqID) cancelAnimationFrame(reqID);
      startWatcher();
    }
  }, [disableWatching]);

  useEffect(
    () => () => {
      cancelAnimationFrame(reqID);
    },
    [],
  );

  return {
    positionTarget,
    foundedNode,
  };
};
export default useElPosDetector;
