import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery, Shadow } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'tabs-container': {
    marginBottom: '16px',
    ...MediaQuery.down({
      '&.with-mobile-shadow': {},
      ...Shadow('rgb(48 43 43 / 50%) 0px 2px 4px 0px'),
      marginTop: props => props.marginTopMobile,
    }).sm,
    overflow: 'hidden',
    '& .tab-row': {
      display: 'flex',
      width: 'fit-content',
      position: 'relative',
      backgroundColor: variables.$baseColorDark_30,
      transition: '.3s',
      transform: props => `translateX(${props.rowTranslateX}px)`,
      '&::after': {
        transition: props => props.transition,
        content: '""',
        position: 'absolute',
        bottom: 0,
        height: '2px',
        width: props => props.underlineWidth,
        left: props => props.underlineLeft,
        background: '#B0F2F1',
      },
    },
    '& .tab-item-pl': {
      minWidth: props => props.minWidth,
      width: props => props.underlineWidth,
      transition: 'background .3s',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      padding: '16px 13px',
      color: 'rgba(255, 255, 255, .6)',
      fontSize: '11px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textAlign: 'center',
      cursor: 'pointer',
      flex: 1,
      '&.active-tab': {
        color: '#B0F2F1',
        backgroundColor: variables.$baseColorDark_50,
      },
    },
  },
});
