import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  'read-more': {
    overflow: 'hidden',
    '& .read-more__button': {
      display: 'inline',
      backgroundColor: 'transparent',
      outline: 'none',
      border: '0',
      padding: '0',
      margin: '0',
      fontSize: 'inherit',
      cursor: 'pointer',
      color: 'inherit',
    },
  },
});
