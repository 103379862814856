import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery, Shadow } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'label-picker-container': {
    marginBottom: '16px',
    height: '43px',
    position: 'relative',
    display: 'block',
    ...MediaQuery.down({
      display: 'none',
    }).sm,
  },
  'picker-btns--block': {
    top: '0px',
    left: '0px',
    width: '100%',
    bottom: '0px',
    height: '0px',
    margin: 'auto',
    display: 'flex',
    padding: '0px 10px',
    zIndex: '9',
    position: 'absolute',
    alignItems: 'center',
    '&.prev-pseudo::before': {
      content: '""',
      background:
        'linear-gradient(90deg, #003F8F 80%, rgba(0, 63, 143, 0) 100%)',
      width: '40px',
      height: '43px',
      position: 'absolute',
      left: '0',
      zIndex: '-1',
    },
    '&.next-pseudo::after': {
      content: '""',
      background:
        'linear-gradient(90deg, #003F8F 80%, rgba(0, 63, 143, 0) 100%)',
      width: '40px',
      height: '43px',
      position: 'absolute',
      zIndex: '-1',
      right: '0',
      transform: 'rotate(180deg)',
    },
    '& span': {
      position: 'absolute',
      fontSize: '20px',
      fontWeight: '900',
      color: '#fff',
      cursor: 'pointer',
      top: '0',
      bottom: '0',
      margin: 'auto',
      height: '20px',
      width: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&::before': {
        zIndex: '222',
        position: 'absolute',
        fontSize: '14px',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        transformOrigin: 'center',
        width: '30px',
        height: '30px',
        backgroundColor: variables.$darkOpacity50,
        ...Shadow('0 2px 4px 0 rgb(0 0 0 / 30%)'),
        borderRadius: '50%',
      },
      '&[disabled]': {
        display: 'none',
      },

      '&.icon-Chevron---Left': {
        left: '5px',
      },
      '&.icon-Chevron---Right': {
        right: '5px',
      },
    },
  },
  'labels-wrapper': {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0px',
      backgroundColor: 'transparent',
    },
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '0',
      background: 'transparent',
    },
    '& div[class*="wrapper-tag"]': {
      flexShrink: '0',
    },
  },
  'label-picker-container__mobile': {
    display: 'none',
    ...MediaQuery.down({
      display: 'block',
    }).sm,
  },
  'labels-wrapper--mobile': {
    '& .lb-expanded-list': {
      fontSize: '13px',
      color: '#fff',
      marginTop: '10px',
      '& span': {
        fontSize: '10px',
        color: variables.$iconColor,
        marginLeft: '5px',
      },
    },
  },
});
