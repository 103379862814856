// TODO::: Need to remove local logic and use one of these packages https://www.chameleon.io/blog/react-product-tour
const getIntroSteps = isMobile => {
  const introSteps = [
    {
      element: 'account-icon',
      title: 'Your new My Sexjobs menu',
      description:
        'Welcome to your new My Sexjobs environment. This is the menu to navigate through My Sexjobs.',
      next: 'notification-icon',
      pointer: {
        height: 60,
        width: 60,
      },
    },
    {
      element: 'notification-icon',
      title: 'Introducing notifications',
      description:
        'We added notifications. Here you can find important information updates about your advertisements and account.',
      next: 'secondary-bar',
      pointer: {
        height: 60,
        width: 60,
      },
    },
    {
      element: 'secondary-bar',
      title: 'Secondary Bar',
      description:
        'We added notifications. Here you can find important information updates about your advertisements and account.!',
      radiusPointer: '50px',
      next: 'highlighted-bar',
    },
    {
      element: 'highlighted-bar',
      title: 'Highlighted Bar',
      description:
        'We added notifications. Here you can find important information updates about your advertisements and account.!',
      radiusPointer: '50px',
      ...(!isMobile && { next: 'most-viewed' }),
    },
  ];

  if (!isMobile) {
    introSteps.push({
      element: 'most-viewed',
      title: 'Most Viewed',
      description:
        'We added notifications. Here you can find important information updates about your advertisements and account.!',
      radiusPointer: '50px',
    });
  }

  return introSteps;
};

export default getIntroSteps;
