import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import debounce from 'utils/debounce';

import useStyles from './styles';

const READ_MORE_LABEL = 'Read More';
const SHOW_LESS_LABEL = 'Read Less';

const ReadMore = props => {
  const {
    numberOfLines,
    lineHeight,
    flexibleContainer,
    ellipses,
    textStyle,
    text,
    showLessButton,
    readMoreLabel,
    showLessLabel,
  } = props;
  const styles = useStyles();
  const [showingAllText, setShowingAllText] = useState(false);
  const [readMoreText, setReadMoreText] = useState(text);
  const [readMoreAction, setReadMoreAction] = useState(false);

  const readMoreWrapper = useRef();

  const getHasTooMuchContent = () =>
    readMoreWrapper.current?.scrollHeight >
    readMoreWrapper.current?.offsetHeight;
  const handleResize = useCallback(() => {
    if (showingAllText) {
      return;
    }
    setReadMoreText(text);
    setReadMoreAction(false);
  }, [showingAllText, text]);

  const toggleReadMore = useCallback(
    ({ forceHide }) => {
      const _showingAllText = forceHide || showingAllText;
      const _readMoreAction = !(showingAllText && !showLessButton);
      const _readMoreText = _showingAllText ? readMoreText : text;

      setReadMoreText(_readMoreText);
      setReadMoreAction(_readMoreAction);
      setShowingAllText(!_showingAllText);
    },
    [showingAllText, readMoreText, text],
  );

  const updateContent = useCallback(() => {
    let trimmedText;
    let _readMoreAction;
    let _readMoreText;
    let teaserWordsArray;
    const hasTooMuchContent = getHasTooMuchContent();

    if (hasTooMuchContent) {
      _readMoreAction = !(showingAllText && !showLessButton);
      teaserWordsArray = readMoreText.split(' ');
      teaserWordsArray.pop();
      trimmedText = `${teaserWordsArray.join(' ')}`;
      _readMoreText = `${trimmedText}${ellipses}`;

      setReadMoreText(_readMoreText);
      setReadMoreAction(_readMoreAction);
    }
  }, [showingAllText, readMoreText, ellipses]);

  const cleanupResize = () => {
    if (showingAllText) {
      toggleReadMore({ forceHide: true });
    }
    updateContent();
  };

  useEffect(() => {
    updateContent();

    if (flexibleContainer && typeof window !== 'undefined') {
      window.addEventListener('resize', debounce(handleResize, 400));
    }
    return () => {
      if (flexibleContainer && typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  useEffect(() => {
    updateContent();
  });

  useEffect(() => {
    if (readMoreText === text && !readMoreAction) {
      cleanupResize();
    }
  }, [readMoreText, readMoreAction]);

  return (
    <div
      style={{
        lineHeight,
        maxHeight: showingAllText ? 'none' : `${numberOfLines * lineHeight}em`,
        ...(textStyle || {}),
      }}
      className={styles['read-more']}
      ref={readMoreWrapper}
    >
      {readMoreText}{' '}
      {readMoreAction ? (
        <button
          onClick={() => toggleReadMore({})}
          className="read-more__button"
        >
          {showingAllText ? showLessLabel : readMoreLabel}
        </button>
      ) : (
        ''
      )}
    </div>
  );
};
ReadMore.defaultProps = {
  lineHeight: 1.5,
  showLessButton: true,
  flexibleContainer: false,
  ellipses: '...',
  readMoreLabel: READ_MORE_LABEL,
  showLessLabel: SHOW_LESS_LABEL,
};
ReadMore.propTypes = {
  numberOfLines: PropTypes.number.isRequired,
  lineHeight: PropTypes.number,
  ellipses: PropTypes.string,
  textStyle: PropTypes.object,
  readMoreLabel: PropTypes.string,
  showLessLabel: PropTypes.string,
  showLessButton: PropTypes.bool,
  flexibleContainer: PropTypes.bool,
};
export default React.memo(ReadMore);
